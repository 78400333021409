<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <h1 class="m-0 text-light">
                {{ t('pages.resetPassword.resetPassword') }}
            </h1>
        </div>
        <div class="card shadow-sm p-3">
            <div v-if="!loading">
                <div v-if="!invalidToken">
                    <div class="mb-3">
                        <label for="email" class="form-label">E-mail</label>
                        <input
                            type="text"
                            class="form-control"
                            id="email"
                            v-model="email"
                            disabled
                        />
                    </div>
                    <div class="mb-3">
                        <label for="newPassword" class="form-label">{{
                            t('pages.resetPassword.newPassword')
                        }}</label>
                        <input
                            @keyup.enter="submit"
                            :placeholder="t('pages.resetPassword.newPassword')"
                            type="text"
                            v-model="password"
                            class="form-control"
                            :class="{ 'is-invalid': invalidPassword }"
                            id="newPassword"
                        />
                        <div v-if="invalidPassword" class="text-danger mt-1">
                            {{ t('pages.resetPassword.eightChars') }}
                        </div>
                    </div>
                    <button @click="submit" class="btn btn-primary mb-3 w-100">
                        {{ t('pages.resetPassword.resetPassword') }}
                    </button>
                </div>
                <div v-if="invalidToken" class="mb-3">
                    <h4>{{ t('pages.resetPassword.expiredHeading') }}</h4>
                    {{ t('pages.resetPassword.expiredExplanation') }}
                    <router-link
                        to="/login/reset"
                        class="mt-3 btn btn-primary w-100"
                        >{{ t('pages.resetPassword.newRequest') }}</router-link
                    >
                </div>
                <router-link to="/" class="btn bg-light text-dark w-100">{{
                    t('pages.resetPassword.cancel')
                }}</router-link>
                <div class="mt-4 text-center">
                    <a
                        href="#"
                        @click.prevent="switchLanguage()"
                        class="text-primary mb-3"
                    >
                        <span v-if="i18n.locale.value == 'fr'"> English </span>
                        <span v-else> Francais </span>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a class="dropdown-item" href="#">English</a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#">Français</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="d-flex flex-row justify-content-center align-items-center"
                style="min-height: 300px"
                v-else
            >
                <img
                    src="@/assets/loading-icon.gif"
                    style="width: 1.5rem; height: 1.5rem"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import axios from 'axios';
import { onMounted, ref, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user-store';
import router from '@/router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const i18n = useI18n();
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');

const route = useRoute();
const loading = ref(true);
const email = ref(null);
const password = ref(null);
const invalidPassword = ref(false);
const invalidToken = ref(false);
const error = ref(false);

async function getInfo() {
    try {
        loading.value = true;
        const response = await axios.get(
            `reset-password/${route.params.token}/email`
        );
        email.value = response.data.email;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        invalidToken.value = true;
        return error.response;
    }
}

function switchLanguage() {
    if (i18n.locale.value == 'fr') {
        i18n.locale.value = 'en';
    } else {
        i18n.locale.value = 'fr';
    }
}

async function submit() {
    try {
        if (password.value != null && password.value.length >= 8) {
            loading.value = true;
            await axios.post(`reset-password/${route.params.token}`, {
                password: password.value,
            });
            // Attempt a new login after password reset
            let res = await axios.post('login', {
                email: email.value,
                password: password.value,
            });
            const userStore = useUserStore();
            userStore.clearUser();
            userStore.setUserDetails(res.data);
            loading.value = false;
            router.push('/?dialog=reset-password');
        } else {
            password.value = null;
            invalidPassword.value = true;
        }
    } catch (err) {
        loading.value = false;
        error.value = true;
    }
}

onMounted(() => {
    getInfo();
});
</script>
